import { useState } from "react";
import {
  motion,
  useMotionValue,
  useTransform,
  AnimatePresence,
} from "framer-motion";

function Card(props) {
  const [exitX, setExitX] = useState(0);
  const x = useMotionValue(0);
  const scale = useTransform(x, [-150, 0, 150], [0.5, 1, 0.5]);
  const rotate = useTransform(x, [-150, 0, 150], [-45, 0, 45]);

  const variantsFrontCard = {
    animate: { scale: 1, y: 0, opacity: 1 },
    exit: (custom) => ({
      x: custom,
      opacity: 0,
      scale: 0.5,
      transition: { duration: 0.2 },
    }),
  };
  const variantsBackCard = {
    initial: { scale: 0, y: 155, opacity: 0 },
    animate: { scale: 0.85, y: 60, opacity: 0.5 },
  };

  function handleDragEnd(_, info) {
    if (info.offset.x < -200) {
      setExitX(-350);
      props.setIndex(props.index + 1);
    } else if (info.offset.x > 200) {
      setExitX(350);
      props.setIndex(props.index + 1);
    }
  }

  return (
    <motion.div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        x,
        rotate,
        cursor: "grab",
      }}
      whileTap={{ cursor: "grabbing" }}
      drag={props.drag}
      dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
      onDragEnd={handleDragEnd}
      variants={props.frontCard ? variantsFrontCard : variantsBackCard}
      initial="initial"
      animate="animate"
      exit="exit"
      custom={exitX}
      transition={
        props.frontCard
          ? { type: "spring", stiffness: 300, damping: 20 }
          : { scale: { duration: 0.2 }, opacity: { duration: 0.4 } }
      }
    >
      <motion.div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "var(--accent-three)",
          borderRadius: "var(--border-radius)",
          scale,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(var(--dark-color))",
          padding: "2rem",
          gap: "2rem",
          textAlign: "center",
        }}
      >
        <div
          style={{
            background: "var(--accent-four)",
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
            color: "rgb(var(--light-color))",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.5rem",
          }}
        >
          {props.content.id}
        </div>
        <div>{props.content.title}</div>
      </motion.div>
    </motion.div>
  );
}

export function ResesarchQuestions({ researchAnalysis }) {
  const [index, setIndex] = useState(0);
  // const content = ["One", "Two", "Three"];
  const safeIndex = (i) => i % content.length;

  const content = [
    {
      id: 1,
      title: researchAnalysis.analysisQuestion1,
    },
    {
      id: 2,
      title: researchAnalysis.analysisQuestion2,
    },
    {
      id: 3,
      title: researchAnalysis.analysisQuestion3,
    },
  ];

  return (
    <motion.div
      style={{
        width: "100%",
        maxWidth: 400,
        aspectRatio: 1,
        position: "relative",
        left: "50%",
        top: "50%",
        transform: " translate(-50%, -50%)",
      }}
      data-cs-normalized
    >
      <AnimatePresence initial={false}>
        <Card
          key={index + 2}
          content={content[safeIndex(index)]}
          frontCard={false}
        />
        <Card
          key={index + 1}
          content={content[safeIndex(index)]}
          frontCard={false}
        />
        <Card
          key={index}
          content={content[safeIndex(index)]}
          frontCard={true}
          index={index}
          setIndex={setIndex}
          drag="x"
        />
      </AnimatePresence>
    </motion.div>
  );
}
