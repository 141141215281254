const ControlButton = ({onNext, onPrev}) => {
  return (
    <div className="button_style">
      <button
        onClick={onPrev}
        // ◀ scroll left
        // style={buttonStyle("left")}
        // className="swiper-button-prev"
      >
        ◀
      </button>

      <button
        onClick={onNext}
        // ▶ scroll right
        // style={buttonStyle("left")}
        // className="swiper-button-next"
      >
        ▶
      </button>
    </div>
  );
};


export default ControlButton;
