// components/CustomCursor.jsx
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useWindowSize from "../services/windowSizeService";

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);
  const [label, setLabel] = useState("");

  const windowSize = useWindowSize();

  useEffect(() => {
    const move = (e) => setPosition({ x: e.clientX, y: e.clientY });
    const checkHover = (e) => {
      const target = e.target.closest(".hover-target");
      if (target) {
        setHovered(true);
        setLabel(target.getAttribute("data-cursor-label") || "");
      } else {
        setHovered(false);
        setLabel("");
      }
    };

    window.addEventListener("mousemove", move);
    window.addEventListener("mouseover", checkHover);

    return () => {
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseover", checkHover);
    };
  }, []);

  return (
    <motion.div
      className="custom-cursor"
      animate={{
        x: position.x - -20,
        y: position.y - -25,
        scale: hovered ? 1 : 0,
      }}
      transition={{ type: "spring", stiffness: 500, damping: 30 }}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "max-content",
        height: "40px",
        backgroundColor: "rgb(var(--dark-color))",
        padding: "0 5px",
        color: "rgb(var(--light-color))",
        borderRadius: "6px",
        pointerEvents: "none",
        zIndex: 9999,
        display: windowSize < 768 ? "none" : "flex",
        alignItems: "center",
        justifyContent: "center",
        mixBlendMode: "difference",
      }}
    >
      <AnimatePresence>
        {hovered && (
          <motion.div
            key="label"
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.7 }}
            transition={{ duration: 0.2 }}
          >
            {label}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default CustomCursor;
