import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./image-carousel.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

function ImageCarousel(props) {
  const [currentImg, setCurrentImg] = useState([]);

  useEffect(() => {
    const createImages = () => {
      const images = props.images;
      setCurrentImg(images.map((image) => image.img));
    };

    createImages();
  }, [props.images]);

  return (
    <Swiper
      style={{ margin: "5rem auto" }}
      loop={true}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      centeredSlides={true}
      pagination={{
        type: "",
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      data-animation="fade-in"
    >
      {currentImg.map((cImg, index) => {
        return (
          <SwiperSlide key={index} className="mySwiper">
            <img
              data-animation="fade-in"
              src={cImg}
              style={{ objectFit: "contain" }}
              // loading="lazy"
              alt="sliding images"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default ImageCarousel;
