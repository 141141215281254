import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./switch.css";

function ThemeSwitch({
  work,
  duration,
  easing,
  reverseColor,
  changeColor,
  theme,
}) {

  function monitorThemeChange() {
    if (theme === "dark") {
      
      changeColor();
    } else {
      reverseColor();
    }
  }

  return (
    <motion.label
      className="switch"
      initial={{ opacity: 0, top: "0" }}
      animate={{ top: !work ? "30%" : 0, opacity: !work ? 1 : 0 }}
      exit={{
        opacity: 0,
        top: 0,
        transition: { duration: duration, ease: easing },
      }}
      transition={{
        duration: duration,
        ease: easing,
        delay: !work ? duration : 0,
      }}
      onClick={() => monitorThemeChange()}
    >
      <div className="switch_top_curve">
        <div className="switch_top_curve_inner"></div>
      </div>
      <input
        type="checkbox"
        id="theme-switcher"
        style={{ position: "relative", left: "30px" }}
      />
      <span className="slider"></span>
      <div className="switch_bottom_curve">
        <div className="switch_bottom_curve_inner"></div>
      </div>
    </motion.label>
  );
}

export default ThemeSwitch;
