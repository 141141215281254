import React, { useState, useEffect } from "react";
import useWindowSize from "../services/windowSizeService";


const width = window.innerWidth; 
const getTime = (width) => {
  const date = new Date();
  let hours = date.getHours(); // Use let instead of const
  const minutes = date.getMinutes();
  let timeOfDay = "AM";
  

  if (width > 768) {
    hours = hours % 12 || 12; // Converts 0 (midnight) to 12
    timeOfDay = date.getHours() >= 12 ? "PM" : "AM";
  }

   return `${hours}:${minutes.toString().padStart(2, "0")}${
     width > 768 ? " " + timeOfDay : ""
   }`;
};



const getTimeOfDay = () => {
  const date = new Date();
  let hours = date.getHours(); // Use let instead of const

  const timeOfDay = hours < 12 ? "AM" : "PM";

  return `${timeOfDay}`;
};

const Clock = () => {
  const [time, setTime] = useState(getTime());
  const windowWidth = useWindowSize();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <h1 >
      {time}{" "}
      <span
        style={{ fontSize: "1.5rem" }}
        className={`${windowWidth < 768 && "d-none"}`}
      >
        {getTimeOfDay()}
      </span>
    </h1>
  );
};

export default Clock;
