import React from "react";
import "./numbered-card.css";

function NumberedCard({ understandUsers }) {
  return (
    <div className="">
      <div className="row gy-2">
        {understandUsers.map((whatUserSaid) => {
          return (
            <div className="col-md-4">
              <div className="numbered-card-cover">
                <div className="numbered-card">
                  <div
                    className="row"
                    style={{
                      position: "absolute",
                      padding: "4rem 1rem",
                    }}
                  >
                    <div
                      className="col-12"
                      style={{ margin: "4rem auto", fontFamily: "Poppins" }}
                    >
                      <h4 style={{ fontFamily: "Poppins" }}>
                        {whatUserSaid.speech}
                      </h4>
                    </div>
                    <div className="col-12">
                      <p data-cs-normalized style={{ fontFamily: "Poppins" }}>
                        {whatUserSaid.insight}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "150px",
                      position: "absolute",
                      top: "-55px",
                      right: "-15px",
                    }}
                  >
                    <p
                      style={{
                        margin: "0",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        opacity: ".1",
                      }}
                    >
                      0{whatUserSaid.id}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NumberedCard;
