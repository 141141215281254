import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";

import { motion, AnimatePresence } from "framer-motion";
import ControlButton from "./control-botton";
import Card from "./Card";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

import "./swiper-component.css";

const SwiperComponent = ({ lists, work, duration, easing }) => {
  const [hoveredImages, setHoveredImages] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const swiperRef = useRef(null);

  const reducedArray = lists.slice(0, 5);

  const changeImage = (i) => {
    setHoveredImages((prev) => ({ ...prev, [i]: 1 }));
  };

  const backToDefault = (i) => {
    setHoveredImages((prev) => ({ ...prev, [i]: 0 }));
  };

  const currentImageIndex = hoveredImages[lists.id] || 0;

  return (
    <AnimatePresence mode="wait">
      
        {work && (
          <div className="button_style_cover">
            <motion.div
              initial={{ opacity: 0, y: "100%" }}
              animate={{ y: !work ? "100%" : 0, opacity: 1 }}
              exit={{
                opacity: 0,
                y: "100%",
                transition: { duration: duration, ease: easing },
              }}
              transition={{ duration: duration, ease: easing }}
            >
              <ControlButton
                onNext={() => swiperRef.current?.slideNext()}
                onPrev={() => swiperRef.current?.slidePrev()}
              />
            </motion.div>
          </div>
        )}
      
      {work && (
        <motion.div
          className="scroller_container"
          initial={{ opacity: 0, x: "100%", y: "-50%" }}
          animate={{ x: !work ? "-100%" : 0, opacity: 1, y: "-50%", zIndex: "7" }}
          exit={{
            opacity: 0,
            x: "100%",
            y: "-50%",
            transition: { duration: duration, ease: easing },
          }}
          transition={{ duration: duration, ease: easing }}
        >
          <Swiper
            modules={[Navigation, FreeMode]}
            freeMode={true}
            freeModeMomentum={true}
            freeModeMomentumRatio={0.9}
            freeModeSticky={false}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            className="projects_slide"
          >
            {[...reducedArray, ...reducedArray, ...reducedArray].map(
              (item, i) => (
                <SwiperSlide key={i}>
                  <Card item={item} />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SwiperComponent;
