import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CaseStudy from "./case-studies";
import { motion, AnimatePresence } from "framer-motion";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./modal.css";

export default function TransitionsModal({ open, handleClose, item }) {
  return (
    <div className="modal-font">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: "backOut" }}
            >
              <Box className="modal_cover">
                <Fab
                  aria-label="close"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px dashed rgba(var(--dark-color), .3)",
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    cursor: "pointer",
                    color: "rgb(var(--dark-color))",
                  }}
                  onClick={handleClose}
                >
                  <CloseRoundedIcon />
                </Fab>
                <CaseStudy project={item} />
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Modal>
    </div>
  );
}
