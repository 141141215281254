import * as React from "react";
const DesignTimelineSvg = ({ designProcess }) => (
  <svg
  className="design-timeline"
    width={1400}
    height="100%"
    viewBox="0 0 1105 413"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 2258" clipPath="url(#clip0_1_664)">
      <g id="Frame 3">
        <g id="&#230;&#151;&#182;&#233;&#151;&#180;&#231;&#186;&#191;&#229;&#186;&#149;">
          <g id="Frame 2259">
            <text
              id={1}
              opacity={0.7}
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              letterSpacing="0em"
            >
              <tspan x={0.28125} y={15.988}>
                {designProcess.planDuration}
              </tspan>
            </text>
          </g>
          <path
            id="Fill 239"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 54.5C0 45.0719 0 40.3579 2.92893 37.4289C5.85786 34.5 10.5719 34.5 20 34.5H65C74.4281 34.5 79.1421 34.5 82.0711 37.4289C85 40.3579 85 45.0719 85 54.5V384.5C85 393.928 85 398.642 82.0711 401.571C79.1421 404.5 74.4281 404.5 65 404.5H20C10.5719 404.5 5.85786 404.5 2.92893 401.571C0 398.642 0 393.928 0 384.5V54.5Z"
            fill="#212121"
          />
        </g>
        <g id="Frame 2241">
          <g id="Frame 2259_2">
            <text
              id="1_2"
              opacity={0.7}
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              letterSpacing="0em"
            >
              <tspan x={170.125} y={15.988}>
                {designProcess.empathyDuration}
              </tspan>
            </text>
          </g>
          <path
            id="Fill 239_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M170 54.5C170 45.0719 170 40.3579 172.929 37.4289C175.858 34.5 180.572 34.5 190 34.5H235C244.428 34.5 249.142 34.5 252.071 37.4289C255 40.3579 255 45.0719 255 54.5V384.5C255 393.928 255 398.642 252.071 401.571C249.142 404.5 244.428 404.5 235 404.5H190C180.572 404.5 175.858 404.5 172.929 401.571C170 398.642 170 393.928 170 384.5V54.5Z"
            fill="#212121"
          />
        </g>
        <g id="Frame 2242">
          <g id="Frame 2259_3">
            <text
              id="1_3"
              opacity={0.7}
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              letterSpacing="0em"
            >
              <tspan x={340.219} y={15.988}>
                {designProcess.defineDuration}
              </tspan>
            </text>
          </g>
          <path
            id="Fill 239_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M340 54.5C340 45.0719 340 40.3579 342.929 37.4289C345.858 34.5 350.572 34.5 360 34.5H405C414.428 34.5 419.142 34.5 422.071 37.4289C425 40.3579 425 45.0719 425 54.5V384.5C425 393.928 425 398.642 422.071 401.571C419.142 404.5 414.428 404.5 405 404.5H360C350.572 404.5 345.858 404.5 342.929 401.571C340 398.642 340 393.928 340 384.5V54.5Z"
            fill="#212121"
          />
        </g>
        <g id="Frame 2243">
          <g id="Frame 2259_4">
            <text
              id="1_4"
              opacity={0.7}
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              letterSpacing="0em"
            >
              <tspan x={510.367} y={15.988}>
                {designProcess.ideateDuration}
              </tspan>
            </text>
          </g>
          <path
            id="Fill 239_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M510 54.5C510 45.0719 510 40.3579 512.929 37.4289C515.858 34.5 520.572 34.5 530 34.5H575C584.428 34.5 589.142 34.5 592.071 37.4289C595 40.3579 595 45.0719 595 54.5V384.5C595 393.928 595 398.642 592.071 401.571C589.142 404.5 584.428 404.5 575 404.5H530C520.572 404.5 515.858 404.5 512.929 401.571C510 398.642 510 393.928 510 384.5V54.5Z"
            fill="#212121"
          />
        </g>
        <g id="Frame 2244">
          <g id="Frame 2259_5">
            <text
              id="1_5"
              opacity={0.7}
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              letterSpacing="0em"
            >
              <tspan x={680.18} y={15.988}>
                {"4 Months"}
              </tspan>
            </text>
          </g>
          <path
            id="Fill 239_5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M680 54.5C680 45.0719 680 40.3579 682.929 37.4289C685.858 34.5 690.572 34.5 700 34.5H745C754.428 34.5 759.142 34.5 762.071 37.4289C765 40.3579 765 45.0719 765 54.5V384.5C765 393.928 765 398.642 762.071 401.571C759.142 404.5 754.428 404.5 745 404.5H700C690.572 404.5 685.858 404.5 682.929 401.571C680 398.642 680 393.928 680 384.5V54.5Z"
            fill="#212121"
          />
        </g>
        <g id="Frame 2245">
          <g id="Frame 2259_6">
            <text
              id="1_6"
              opacity={0.7}
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              letterSpacing="0em"
            >
              <tspan x={850.484} y={15.988}>
                {"1 Month"}
              </tspan>
            </text>
          </g>
          <path
            id="Fill 239_6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M850 54.5C850 45.0719 850 40.3579 852.929 37.4289C855.858 34.5 860.572 34.5 870 34.5H915C924.428 34.5 929.142 34.5 932.071 37.4289C935 40.3579 935 45.0719 935 54.5V384.5C935 393.928 935 398.642 932.071 401.571C929.142 404.5 924.428 404.5 915 404.5H870C860.572 404.5 855.858 404.5 852.929 401.571C850 398.642 850 393.928 850 384.5V54.5Z"
            fill="#212121"
          />
        </g>
        <g id="Frame 2247">
          <g id="Frame 2259_7">
            <text
              id="1_7"
              opacity={0.7}
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
                textAlign: "center",
              }}
              fontFamily="Poppins"
              fontSize={16}
              letterSpacing="0em"
            >
              <tspan x={1020.35} y={15.988}>
                {""}
              </tspan>
            </text>
          </g>
          <path
            id="Fill 239_7"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1020 54.5C1020 45.0719 1020 40.3579 1022.93 37.4289C1025.86 34.5 1030.57 34.5 1040 34.5H1085C1094.43 34.5 1099.14 34.5 1102.07 37.4289C1105 40.3579 1105 45.0719 1105 54.5V384.5C1105 393.928 1105 398.642 1102.07 401.571C1099.14 404.5 1094.43 404.5 1085 404.5H1040C1030.57 404.5 1025.86 404.5 1022.93 401.571C1020 398.642 1020 393.928 1020 384.5V54.5Z"
            fill="#212121"
          />
        </g>
      </g>
      <g id="&#230;&#180;&#187;&#229;&#138;&#168;&#231;&#137;&#135;">
        <g clipPath="url(#clip1_1_664)">
          <path
            id="Fill 59"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 93.5H85V175.762H0V93.5Z"
            fill="var(--accent-three)"
          />
          <text
            id="Kicko&#239;&#172;&#128;"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={11} y={117.052}>
              {"Empathy"}
            </tspan>
          </text>
        </g>
        <rect x={-0.5} y={93} width={86} height={83} rx={6.5}  />
      </g>
      <g id="Frame 2244_2">
        <g clipPath="url(#clip2_1_664)">
          <path
            id="Fill 59_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M170 257.5H255V339.762H170V257.5Z"
            fill="rgb(var(--accent-color))"
          />
          <text
            id="Kicko&#239;&#172;&#128;_2"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={174} y={286.552}>
              {"Wireframe\n"}
            </tspan>
            <tspan x={174} y={303.618}>
              {"and \n"}
            </tspan>
            <tspan x={174} y={320.684}>
              {"design"}
            </tspan>
          </text>
        </g>
        <rect
          x={169.5}
          y={257}
          width={86}
          height={83}
          rx={6.5}
          
        />
      </g>
      <g id="Frame 2245_2">
        <g clipPath="url(#clip3_1_664)">
          <path
            id="Fill 59_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M340 257.5H425V339.762H340V257.5Z"
            fill="rgb(var(--accent-color))"
          />
          <text
            id="Kicko&#239;&#172;&#128;_3"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={351} y={281.052}>
              {"Finalize "}
            </tspan>
            <tspan x={351} y={298.118}>
              {"design"}
            </tspan>
          </text>
        </g>
        <rect
          x={339.5}
          y={257}
          width={86}
          height={83}
          rx={6.5}
          
        />
      </g>
      <g id="Frame 2246">
        <g clipPath="url(#clip4_1_664)">
          <path
            id="Fill 59_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M848 257.5H933V339.762H848V257.5Z"
            fill="rgb(var(--accent-color))"
          />
          <text
            id="Kicko&#239;&#172;&#128;_4"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={863.538} y={281.052}>
              {"Testing "}
            </tspan>
            <tspan x={859.758} y={298.118}>
              {"and bug "}
            </tspan>
            <tspan x={869.109} y={315.184}>
              {"fixing"}
            </tspan>
          </text>
        </g>
        <rect
          x={847.5}
          y={257}
          width={86}
          height={83}
          rx={6.5}
          
        />
      </g>
      <g id="Frame 2247_2">
        <g clipPath="url(#clip5_1_664)">
          <path
            id="Fill 59_5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1020 257.5H1105V339.762H1020V257.5Z"
            fill="var(--accent-five)"
          />
          <text
            id="Kicko&#239;&#172;&#128;_5"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={1031} y={281.052}>
              {"Release"}
            </tspan>
          </text>
        </g>
        <rect
          x={1019.5}
          y={257}
          width={86}
          height={83}
          rx={6.5}
          
        />
      </g>
      <g id="Frame 2240">
        <g clipPath="url(#clip6_1_664)">
          <path
            id="Fill 59_6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M255 93.5H425V175.762H255V93.5Z"
            fill="var(--accent-three)"
          />
          <text
            id="Kicko&#239;&#172;&#128;_6"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={266} y={117.052}>
              {"Design Hifi"}
            </tspan>
          </text>
        </g>
        <rect
          x={254.5}
          y={93}
          width={171}
          height={83}
          rx={6.5}
          
        />
      </g>
      <g id="Frame 2241_2">
        <g clipPath="url(#clip7_1_664)">
          <path
            id="Fill 59_7"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M510 93.5H647V175.762H510V93.5Z"
            fill="var(--accent-five)"
          />
          <text
            id="Kicko&#239;&#172;&#128;_7"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={521} y={117.052}>
              {"Prototype  and "}
            </tspan>
            <tspan x={521} y={134.118}>
              {"Testing (UT)"}
            </tspan>
          </text>
        </g>
        <rect
          x={509.5}
          y={93}
          width={138}
          height={83}
          rx={6.5}
          
        />
      </g>
      <g id="Frame 2242_2">
        <g clipPath="url(#clip8_1_664)">
          <path
            id="Fill 59_8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85 175.5H255V257.762H85V175.5Z"
            fill="var(--accent-three)"
          />
          <text
            id="Kicko&#239;&#172;&#128;_8"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={96} y={199.052}>
              {"Define & Ideate"}
            </tspan>
          </text>
        </g>
        <rect
          x={84.5}
          y={175}
          width={171}
          height={83}
          rx={6.5}
          
        />
      </g>
      <g id="Frame 2243_2">
        <g clipPath="url(#clip9_1_664)">
          <path
            id="Fill 59_9"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M647 175.5H850V257.762H647V175.5Z"
            fill="var(--accent-five)"
          />
          <text
            id="Kicko&#239;&#172;&#128;_9"
            fill="white"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={600}
            letterSpacing="0em"
          >
            <tspan x={658} y={199.052}>
              {"Develop (Code)"}
            </tspan>
          </text>
        </g>
        <rect
          x={646.5}
          y={175}
          width={204}
          height={83}
          rx={6.5}
          
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_664">
        <rect
          width={1105}
          height={412}
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_1_664">
        <rect y={93.5} width={85} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip2_1_664">
        <rect x={170} y={257.5} width={85} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip3_1_664">
        <rect x={340} y={257.5} width={85} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip4_1_664">
        <rect x={848} y={257.5} width={85} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip5_1_664">
        <rect x={1020} y={257.5} width={85} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip6_1_664">
        <rect x={255} y={93.5} width={170} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip7_1_664">
        <rect x={510} y={93.5} width={137} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip8_1_664">
        <rect x={85} y={175.5} width={170} height={82} rx={6} fill="white" />
      </clipPath>
      <clipPath id="clip9_1_664">
        <rect x={647} y={175.5} width={203} height={82} rx={6} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DesignTimelineEx = (props) => (
  <svg
    width={1400}
    height="100%"
    viewBox="0 0 1128 497"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 94">
      <g id="Frame 2239">
        <g id="&#233;&#152;&#182;&#230;&#174;&#181;">
          <g id="Frame 2241">
            <rect y={0.5} width={344} height={34} rx={8} fill="rgba(var(--accent-color), 0.9)" />
            <text
              id="01 RESEARCH"
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              fontWeight={600}
              letterSpacing="0em"
            >
              <tspan x={12} y={23.988}>
                {"01 - Ideation"}
              </tspan>
            </text>
          </g>
          <g id="Frame 10">
            <text
              id="Topline Stakeholder Interviews "
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={72.052}>
                {"Brainstorming"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _2"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={96.052}>
                {"Crazy8"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _3"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={120.052}>
                {"FGD"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _4"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={144.052}>
                {"Research"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _5"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={168.052}>
                {"Interview"}
              </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 2255">
          <g id="Frame 2241_2">
            <rect
              x={352}
              y={0.5}
              width={492}
              height={34}
              rx={8}
              fill="rgba(var(--accent-color), 0.9)"
            />
            <text
              id="01 RESEARCH_2"
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              fontWeight={600}
              letterSpacing="0em"
            >
              <tspan x={364} y={23.988}>
                {"02  Research and wireframe"}
              </tspan>
            </text>
          </g>
          <g id="Frame 10_2">
            <text
              id="Topline Stakeholder Interviews _6"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={72.052}>
                {"Interview"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _7"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={96.052}>
                {"empathy Map"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _8"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={120.052}>
                {"competitor analysis"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _9"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={144.052}>
                {"User journey"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _10"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={168.052}>
                {"General Flow"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _11"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={192.052}>
                {"Design trend Research"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _12"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={216.052}>
                {"Low Fi testing (AB)"}
              </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 2256">
          <g id="Frame 2241_3">
            <rect
              x={852}
              y={0.5}
              width={276}
              height={34}
              rx={8}
              fill="rgba(var(--accent-color), 0.9)"
            />
            <text
              id="01 RESEARCH_3"
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              fontWeight={600}
              letterSpacing="0em"
            >
              <tspan x={864} y={23.988}>
                {"03 Hifi Design"}
              </tspan>
            </text>
          </g>
          <g id="Frame 10_3">
            <text
              id="Topline Stakeholder Interviews _13"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={72.052}>
                {"Brand identity"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _14"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={96.052}>
                {"Design Main Cases"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _15"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={120.052}>
                {"Design Edge Cases"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _16"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={144.052}>
                {"Design UI documentation"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _17"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={168.052}>
                {"Design Sytem"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _18"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={192.052}>
                {"Design Flow Per Cases"}
              </tspan>
            </text>
          </g>
        </g>
      </g>
      <g id="Frame 2259">
        <g id="&#233;&#152;&#182;&#230;&#174;&#181;_2">
          <g id="Frame 2241_4">
            <rect y={253} width={344} height={34} rx={8} fill="rgba(var(--accent-color), 0.9)" />
            <text
              id="01 RESEARCH_4"
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              fontWeight={600}
              letterSpacing="0em"
            >
              <tspan x={12} y={276.488}>
                {"04 Prototype  and Testing (UT) "}
              </tspan>
            </text>
          </g>
          <g id="Frame 10_4">
            <text
              id="Topline Stakeholder Interviews _19"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={324.552}>
                {"General Prototype"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _20"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={348.552}>
                {"Edge cases Prototype"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _21"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={372.552}>
                {"Usability Testing"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _22"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={396.552}>
                {"Gather Feedback"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _23"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={21} y={420.552}>
                {"Design Revision"}
              </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 2255_2">
          <g id="Frame 2241_5">
            <rect
              x={352}
              y={253}
              width={492}
              height={34}
              rx={8}
              fill="rgba(var(--accent-color), 0.9)"
            />
            <text
              id="01 RESEARCH_5"
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              fontWeight={600}
              letterSpacing="0em"
            >
              <tspan x={364} y={276.488}>
                {"05 Development"}
              </tspan>
            </text>
          </g>
          <g id="Frame 10_5">
            <text
              id="Topline Stakeholder Interviews _24"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={324.552}>
                {"Handoff to developer"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _25"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={348.552}>
                {"Assets Documentation"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _26"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={372.552}>
                {"Translate from UI to code"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _27"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={396.552}>
                {"Unit Testing"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _28"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={420.552}>
                {"UI audit"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _29"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={444.552}>
                {"Gather Feedback"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _30"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={468.552}>
                {"Layout Revision after feedback"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _31"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={373} y={492.552}>
                {"Ready to test"}
              </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 2256_2">
          <g id="Frame 2241_6">
            <rect
              x={852}
              y={253}
              width={276}
              height={34}
              rx={8}
              fill="rgba(var(--accent-color), 0.9)"
            />
            <text
              id="01 RESEARCH_6"
              fill="white"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={16}
              fontWeight={600}
              letterSpacing="0em"
            >
              <tspan x={864} y={276.488}>
                {"Testing -> Release"}
              </tspan>
            </text>
          </g>
          <g id="Frame 10_6">
            <text
              id="Topline Stakeholder Interviews _32"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={324.552}>
                {"Testcase Creation"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _33"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={348.552}>
                {"Flow Creation from QA"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _34"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={372.552}>
                {"QA documentation"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _35"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={396.552}>
                {"UI Audit"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _36"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={420.552}>
                {"Revision to developer"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _37"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={444.552}>
                {"General Testing"}
              </tspan>
            </text>
            <text
              id="Topline Stakeholder Interviews _38"
              fill="rgb(var(--light-color))"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Poppins"
              fontSize={14}
              letterSpacing="0em"
            >
              <tspan x={873} y={468.552}>
                {"Release MVP"}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { DesignTimelineSvg, DesignTimelineEx };
