import React from "react";
import LogoSVG from "./mylogo";
import { Link } from "react-router-dom";
import "./header.css";

function Header({
  isVisible,
  setAbout,
  setContact,
  setRotate,
  contact
}) {
  return (
    <div id="header_wrapper" data-animate-fromtop>
      <ul style={{ fontSize: ".75rem", whiteSpace: "nowrap" }}>
        <li>
          <Link to="/">
            <LogoSVG />
          </Link>
        </li>
        <li
          className="p_wrapper hover-target"
          onClick={() => {
            setRotate(90);
            setAbout(true);
            setContact(false);
          }}
          data-cursor-label="👨🏽‍💼 ABOUT ME"
        >
          SOLOMON ONI
        </li>
        <li>
          <div>PRODUCT DESIGNER</div>
          <div>FRONTEND DEVELOPER</div>
        </li>
      </ul>
      <div style={{height: "max-content"}}>
        <div
          className="status hover-target"
          onClick={() => {
            setRotate(180);
            setAbout(false);
            setContact(true);
          }}
          data-cursor-label="🤙🏽 LET'S TALK"
        >
          <div className="status-dot"></div>
          <span style={{ fontSize: ".75rem", whiteSpace: "nowrap" }}>
            availble for work
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
