import React, { forwardRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import TransitionsModal from "./modal";
import "./card.css";

const Card = ({ item, i }) => {
  const [hoveredImages, setHoveredImages] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const changeImage = (i) => {
    setHoveredImages((prev) => ({ ...prev, [i]: 1 }));
  };

  const backToDefault = (i) => {
    setHoveredImages((prev) => ({ ...prev, [i]: 0 }));
  };

  const currentImageIndex = hoveredImages[i] || 0;


  return (
    <>
      <TransitionsModal open={open} handleClose={handleClose} item={item} />
      <Link onClick={handleOpen}>
        <div
          className="card_style"
          onMouseEnter={() => changeImage(i)}
          onMouseLeave={() => backToDefault(i)}
          whileHover={{ scale: 0.98 }}
          transition={{ duration: 0.3, ease: "backOut" }}
        >
          <div className="card_style_header">
            <div className="card_style_header_content">
              <p>NAME</p>
              <p>{item.name}</p>
            </div>
            <div className="card_style_header_content">
              <p>TYPE</p>
              <p>{item.type}</p>
            </div>
            <div className="card_style_header_content">
              <p>CASE STUDY</p>
              <p>{item.screens}</p>
            </div>
          </div>

          <div className="card_style_inner">
            <motion.img
              src={item.img[currentImageIndex].img}
              alt=""
              initial={{ opacity: 0.8 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0.8,
                transition: { duration: 0.05, ease: "linear" },
              }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              onError={(e) => (e.currentTarget.src = "/images/working.png")}
            />
          </div>

          <div className="card_style_footer">
            <div className="card_style_header_content">
              <p>WEBSITE</p>
              <p>{item.siteLink}</p>
            </div>
            <div className="card_style_header_content">
              <p>SCREENS</p>
              <p>{item.screens}</p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Card;
