import Spline from "@splinetool/react-spline";
import { useState, useEffect } from "react";

function SplineScene({ checkIfLoaded, setRotate, setWork, setScaleDown, setDisplayHidden, triggerAnimation, work }) {

  const [showCursorAttr, setShowCursorAttr] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCursorAttr(true);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer); // cleanup
  }, []);

  return (
    <Spline
      scene="https://prod.spline.design/ZqYsvzuZaS9LAxdY/scene.splinecode"
      onLoad={(spline) => checkIfLoaded(spline)}
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (work != true) {
          setRotate(-90);
          setWork(true);
          setScaleDown(true);
          setDisplayHidden();
          triggerAnimation();
        } else {
          console.warn("Already loaded");
        }
      }}
      className={`${ work ? "" : "hover-target"}`}
      {...(showCursorAttr
        ? { "data-cursor-label": "🤝 LET'S WORK TOGETHER" }
        : {})}
    />
  );
}

export default SplineScene;


