import React, { useState, useRef } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Zoom from "@mui/material/Zoom";
import { motion, AnimatePresence } from "framer-motion";
import { ContactCard } from "./other-cards";
import { AboutCard } from "./other-cards";
// -------------------------------------
// Components Import
// -------------------------------------

import ThemeSwitch from "./switch";
import SplineScene from "./spline";
import Clock from "./time";
import SwiperComponent from "./swiper-component";
import Loader from "./loader";

// -------------------------------------
// Components Import End
// -------------------------------------

import "./landing-page.css";

function LandingPage({
  lists,
  theme,
  work,
  contact,
  about,
  isVisible,
  setAbout,
  setContact,
  setRotate,
  setIsVisible,
  setWork,
  rotate,
}) {
  const [scaleDown, setScaleDown] = useState(false);
  const duration = 0.5;
  const easing = "easeInOut";

  const setDisplayHidden = () => {
    setTimeout(() => {
      setIsVisible(!isVisible);
    }, 100);
  };

  const objectToAnimate = useRef();
  const colorToAnimate = useRef();

  function onLoad(spline) {
    try {
      const obj = spline.findObjectByName("trigger");
      const objColor = spline.findObjectByName("color-trigger");

      if (!obj || !objColor) {
        console.warn("Spline objects not found: trigger or color-trigger");
        return;
      }

      objectToAnimate.current = obj;
      colorToAnimate.current = objColor;
    } catch (error) {
      console.error("Error in Spline onLoad:", error);
    }
  }

  function triggerAnimation() {
    if (objectToAnimate.current?.emitEvent) {
      objectToAnimate.current.emitEvent("mouseDown");
    } else {
      console.warn("triggerAnimation: objectToAnimate not ready.");
    }
  }

  function reverseAnimation() {
    if (objectToAnimate.current?.emitEventReverse) {
      objectToAnimate.current.emitEventReverse("mouseDown");
    } else {
      console.warn("reverseAnimation: objectToAnimate not ready.");
    }
  }

  function changeColor() {
    if (colorToAnimate.current?.emitEvent) {
      colorToAnimate.current.emitEvent("mouseDown");
    } else {
      console.warn("changeColor: colorToAnimate not ready.");
    }
  }

  function reverseColor() {
    if (colorToAnimate.current?.emitEventReverse) {
      colorToAnimate.current.emitEventReverse("mouseDown");
    } else {
      console.warn("reverseColor: colorToAnimate not ready.");
    }
  }

  if (!lists.length) return <Loader />;

  return (
    <div id="landing_page_wrapper">
      <div className="hover-target" data-cursor-label="🎨 CHANGE THEME">
        <ThemeSwitch
          work={work}
          duration={duration}
          easing={easing}
          changeColor={changeColor}
          reverseColor={reverseColor}
          theme={theme}
        />
      </div>

      <main className="landing_page_main">
        <section className="spline_wrapper">
          <SplineScene
            checkIfLoaded={onLoad}
            setRotate={setRotate}
            setWork={setWork}
            setScaleDown={setScaleDown}
            setDisplayHidden={setDisplayHidden}
            triggerAnimation={triggerAnimation}
            lists={lists}
            work={work}
          />
        </section>

        <section className="my_passion" data-animate-in>
          <motion.p
            initial={{ opacity: 1, y: 0 }}
            animate={{ y: work ? "300%" : 0 }}
            exit={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.5, ease: "linear" },
            }}
            transition={{ duration: 0.5, ease: "linear" }}
          >
            I 🤍 CREATIVE DESIGNS THAT MERGE SIMPLICITY, FUTURISTIC &
            INTERACTIVITY WITH USABILITY FOR REAL-WORLD IMPACT.{" "}
          </motion.p>
        </section>

        <section className="time">
          <Clock />
        </section>

        <section className="recent_work" data-animate-in>
          <AnimatePresence>
            <div className="recent_cover">
              <div className="h_cover">
                <motion.p
                  initial={{ opacity: 1, y: 0 }}
                  animate={{ y: work ? "300%" : 0 }}
                  exit={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.5, ease: "linear" },
                  }}
                  transition={{ duration: 0.5, ease: "linear" }}
                  style={{ margin: "clamp(0px, 1vw, 10px)" }}
                >
                  RECENT WORK
                </motion.p>
              </div>
              <motion.div
                className="recent hover-target"
                initial={{ opacity: 1, y: 0 }}
                animate={{ opacity: work ? 0 : 1, y: work ? "300%" : 0 }}
                exit={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.5, ease: "linear" },
                }}
                transition={{ duration: 0.5, ease: "linear" }}
                data-cursor-label="🧑🏽‍💻 SEE PROJECTS"
              >
                <img
                  onClick={() => {
                    if (lists?.[0]?.img?.[0]?.img) {
                      setRotate(-90);
                      setWork(true);
                      setScaleDown(true);
                      setDisplayHidden();
                      triggerAnimation();
                    } else {
                      console.warn("Image source not found for project[0]");
                    }
                  }}
                  src={lists?.[0]?.img?.[0]?.img || null}
                  alt="work-2"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "var(--border-radius)",
                  }}
                />
              </motion.div>
            </div>
          </AnimatePresence>

          <div className={`dial ${scaleDown ? "scale_down" : ""}`}>
            <div
              id="trigger"
              className={`p_wrapper ${!isVisible ? "d-none" : ""}`}
              onClick={() => {
                setRotate(-90);
                setWork(true);
                setContact(false);
                setAbout(false);
                setScaleDown(true);
                setDisplayHidden();
                triggerAnimation();
              }}
            >
              <p className={`${work ? "go_into_hidding" : ""}`}>WORK</p>
            </div>
            <div
              className={`p_wrapper ${!isVisible ? "d-none" : ""}`}
              onClick={() => {
                setRotate(90);
                setAbout(!about);
                setContact(false);
              }}
            >
              <p className={`${work ? "go_into_hidding" : ""}`}>ABOUT</p>
            </div>
            <div
              className={`p_wrapper ${!isVisible ? "d-none" : ""}`}
              onClick={() => {
                setRotate(180);
                setAbout(false);
                setContact(!contact);
              }}
            >
              <p className={`${work ? "go_into_hidding" : ""}`}>CONTACT</p>
            </div>
            <div
              className={`p_wrapper ${!isVisible ? "d-none" : ""}`}
              onClick={() => {
                setRotate(0);
                setAbout(false);
                setContact(false);
              }}
            >
              <p className={`${work ? "go_into_hidding" : ""}`}>INDEX</p>
            </div>

            <div className="frameDiv">
              <div className="pinkDiv"></div>
              <div
                className="circleDiv"
                style={{ transform: `rotate(${rotate}deg)` }}
              >
                {work && (
                  <Zoom
                    in={true}
                    className="xoom"
                    sx={{ width: 40, height: 35 }}
                  >
                    <CloseRoundedIcon
                      onClick={() => {
                        setWork(false);
                        setScaleDown(false);
                        setRotate(0);
                        setDisplayHidden();
                        reverseAnimation();
                      }}
                    />
                  </Zoom>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <SwiperComponent
            lists={lists}
            work={work}
            duration={duration}
            easing={easing}
          />
        </section>
        <section>
          <div className="general_card_cover">
            <AboutCard
              about={about}
              setAbout={setAbout}
              setRotate={setRotate}
            />
            <ContactCard
              contact={contact}
              setContact={setContact}
              setRotate={setRotate}
            />
          </div>
        </section>
      </main>
    </div>
  );
}

export default LandingPage;
