import "./index.css";
import React, { useState, useEffect } from "react";
import { Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/header";
import CustomCursor from "./components/custom-cursor";
import Loader from "./components/loader";

// -------------------------------------
// Services Import
// -------------------------------------

import Lists from "./services/apiService";
import ThemeSwitchService from "./services/themeSwitchService";
import useWindowSize from "./services/windowSizeService";

// -------------------------------------
// Components Import
// -------------------------------------

import LandingPage from "./components/landing-page";

// -------------------------------------
// Components Import End
// -------------------------------------

function App() {
  const [theme, setTheme] = useState("dark"); // Default to dark
  const [darkModeOn, setDarkModeOn] = useState(true);
  const [lists, setLists] = useState([]);
  const windowWidth = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const [work, setWork] = useState(false); // LIFTED STATE
  const [about, setAbout] = useState(false); // LIFTED STATE
  const [contact, setContact] = useState(false); // LIFTED STATE
  const [isVisible, setIsVisible] = useState(true); // LIFTED STATE
  const [rotate, setRotate] = useState(0);

  // ---------------------
  // Restricted routes
  // ---------------------

  const restrictedProjects = {
    1: "9",
    2: "8",
    3: "5",
    4: "2",
    5: "4",
    6: "3",
    7: "1",
  };

  // ---------------------
  // Fetch API Data
  // ---------------------

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const data = await Lists();
        setLists(data);
      } catch (error) {
        console.error("Failed to fetch lists:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLists();
  }, []);

  // ---------------------
  // Force Dark Mode on Load
  // ---------------------
  useEffect(() => {
    document.body.classList.add("dark");
    localStorage.setItem("theme", "dark"); // Ensure dark mode is stored
    setTheme("dark");
    setDarkModeOn(true);
  }, []);

  // ---------------------
  // Theme Switching Logic
  // ---------------------


  useEffect(() => {
    if (isLoading) return; // Wait for loader to finish

    const themeSwitcher = document.getElementById("theme-switcher");

        function isSafari() {
          return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        }

        if (!isSafari()) {
          const imgElement = document.querySelector(".home-inner img");
          if (imgElement) {
            imgElement.classList.add("animate-zoom");
          }
        }

    if (themeSwitcher) {
      loadThemePreference(themeSwitcher);
      themeSwitcher.addEventListener("change", handleThemeToggle);
    }

    return () => {
      if (themeSwitcher) {
        themeSwitcher.removeEventListener("change", handleThemeToggle);
      }
    };
  }, [isLoading]);


  // ---------------------
  // Load dark theme even if the user prefers otherwise – Logic
  // ---------------------

  const loadThemePreference = (themeSwitcher) => {
    document.body.classList.add("dark");
    themeSwitcher.checked = true;
    setTheme("dark");
    setDarkModeOn(true);
  };

  // ---------------------
  // Theme Toggle Logic
  // ---------------------

  const handleThemeToggle = () => {
    document.body.classList.toggle("dark");
    const newTheme = document.body.classList.contains("dark")
      ? "dark"
      : "light";
    setTheme(newTheme);
    setDarkModeOn(newTheme === "dark");
    localStorage.setItem("theme", newTheme);
    ThemeSwitchService.darkModeOn(newTheme === "dark");
  };
return (
  <div>
    <CustomCursor />
    <Header
      setContact={setContact}
      setAbout={setAbout}
      isVisible={isVisible}
      rotate={rotate}
      setRotate={setRotate}
      contact={contact}
    />

    {isLoading ? (
      <Loader /> // You can style this
    ) : (
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <LandingPage
              lists={lists}
              theme={theme}
              work={work}
              setWork={setWork}
              contact={contact}
              setContact={setContact}
              about={about}
              setAbout={setAbout}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              rotate={rotate}
              setRotate={setRotate}
            />
          }
        />
      </Routes>
    )}
  </div>
);

}

export default App;
