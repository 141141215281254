import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import BadgeAvatars from "./avatar";
import { Link } from "react-router-dom";
import "./other-cards.css";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import useWindowSize from "../services/windowSizeService";

const ContactCard = ({ contact, setContact, setRotate }) => {
  

  return (
    <motion.div
      className="contact_card_body"
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: contact ? 1 : 0, y: !contact ? "100%" : 0 }}
      exit={{
        opacity: 0,
        y: "100%",
        transition: { duration: 0.5, ease: "backOut" },
      }}
      transition={{ duration: 0.5, ease: "backOut" }}
      style={{ willChange: "transform" }}
    >
      <Link
        className="contact_card_close"
        onClick={() => {
          setContact(!contact);
          setRotate(0);
        }}
      >
        <CloseRoundedIcon />
      </Link>
      <div className="contact_card">
        <p className="contact_card_title">CONTACT</p>
        <Link
          className="contact_card_email"
          to="mailto:solomonooni@gmail.com"
          target="_blank"
        >
           {" "}
          <Icon
            icon="ic:outline-mail"
            width={24}
            style={{ color: "#ffffff90" }}
          />{" "}
          SOLOMONOONI@GMAIL.COM
        </Link>
      </div>
      <hr />
      <div className="contact_card_socials">
        <Link
          className="socials_card"
          to="https://www.linkedin.com/in/solomon-oni-msc-38763112a/"
          target="_blank"
        >
          <Icon
            icon="line-md:linkedin"
            width={20}
            style={{ color: "rgba(var(--dark-color), .9)", padding: "0" }}
          />
          <span style={{ padding: 0, marginTop: "5px" }}>LINKEDIN</span>
        </Link>
        <Link
          className="socials_card"
          to="https://www.behance.net/payneoni"
          target="_blank"
        >
          <Icon
            icon="ph:behance-logo-bold"
            width={20}
            style={{ color: "rgba(var(--dark-color), .9)", padding: "0" }}
          />
          <span style={{ padding: 0, marginTop: "2px" }}>BEHANCE</span>
        </Link>
        <Link
          className="socials_card"
          to="https://cal.com/solomon-oni"
          target="_blank"
        >
          <Icon
            icon="uil:calender"
            width={20}
            style={{ color: "rgba(var(--dark-color), .9)", padding: "0" }}
          />
          <span style={{ padding: 0 }}>LET'S TALK</span>
        </Link>
      </div>
    </motion.div>
  );
};

const AboutCard = ({ about, setAbout, setRotate }) => {
  const windowWidth = useWindowSize();
  return (
    <motion.div
      className="about_card_body"
      initial={{ opacity: 0, y: "100%" }}
      animate={{
        opacity: about ? 1 : 0,
        y: !about ? "100%" : windowWidth < 400 ? -180 : -160,
      }}
      exit={{
        opacity: 0,
        y: "100%",
        transition: { duration: 0.5, ease: "backOut" },
      }}
      transition={{ duration: 0.5, ease: "backOut" }}
      style={{ willChange: "transform" }}
    >
      <div className="about_card_avatar">
        <BadgeAvatars />
      </div>
      <Link
        className="about_card_close"
        onClick={() => {
          setAbout(!about);
          setRotate(0);
        }}
      >
        <CloseRoundedIcon />
      </Link>
      <div className="about_card">
        <p className="about_card_title">SOLO PAYNE</p>
        <p className="about_card_texts">
          I'm a workaholic (I'm probably working on something now 😀). With 6+
          years of experience, I focus on motion-driven, intuitive interfaces.
          Prototyping is my passion—it sparks new ideas and possibilities. I aim
          for excellence without compromising collaboration. I'm experienced in:
        </p>
        <ul className="about_card_texts">
          <li>Translating insights into data-driven design.</li>
          <li>Accessibility design (WCAG standards) and usability testing.</li>
          <li>Conducting A/B testing to optimize user retention.</li>
          <li>
            Designing with figma, Adobe illustrator, sketch, XD, photoshop,
            after effects, spline etc.
          </li>
          <li>FRONTEND DEVELOPMENT</li>
        </ul>
      </div>
      <hr />
      <div className="about_card_ach">
        {" "}
        <Link
          className="ach_card"
          to="https://coursera.org/share/3ad848419f5b0cca6321d511c09eaa4d"
          target="_blank"
        >
          <Icon icon="flat-color-icons:google" width={20} />{" "}
          <span>GOOGLE CERTIFIED</span>
        </Link>
      </div>
    </motion.div>
  );
};

export { ContactCard, AboutCard };
