import React, { useEffect, useState, useRef, useMemo } from "react";
import axios from "axios";
import ImageCarousel from "./image-carousel";
import DesignProcessSvgComponent from "./design-process-svg";
import Loader from "./loader";
import { Link, useParams } from "react-router-dom";
import SlidingBox from "./sliding-box";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import QueuePlayNextRoundedIcon from "@mui/icons-material/QueuePlayNextRounded";
import useWindowSize from "../services/windowSizeService";
// import JustDesign from "./just-design";
import NumberedCard from "./numbered-card";
import JourneyMapSvg from "./journeymap-svg";
import EmpathyMapSvg from "./empmap-svg";
import { DesignTimelineSvg, DesignTimelineEx } from "./design-timeline-svg";
import { ResesarchQuestions } from "./research-questions";
import ReusableBtn from "./reusable-btn";
import { motion, useAnimation, useInView } from "framer-motion";
import "./case-studies.css";

const url = "https://portfolio-server-nine-nu.vercel.app"; // Backend URL

function CaseStudy({ project }) {
  const { id } = useParams(); // Extract the id from the URL
  const motionRef = useRef(null);
  const isInView = useInView(motionRef, {
    margin: "10% 0px -10% 0px", // fine-tune trigger
  });

  const controls = useAnimation();
  const [isSticky, setSticky] = useState(false);

  //   const [project, setProject] = useState(null);

  const [isVisible, setIsVisible] = useState([false, false, false, false]);
  //   const sectionRefs = useRef([]);
  //   const otherProjects = lists.filter((list) => list.id != id);

  useEffect(() => {
    if (isInView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, ease: "anticipate" },
      });
    } else {
      controls.set({ opacity: 0, y: 50 }); // reset when out of view
    }
  }, [isInView, controls]);

  useEffect(() => {
    const keyword = [
      "balances simplicity",
      "advanced functionalities",
      "cost-effective",
      "caters to the needs of both beginners and professional traders",
    ];
    const para = Array.from(
      document.querySelectorAll("[data-cs-enlarged-callout]")
    );

    para.forEach((paragraph, index) => {
      const regex = new RegExp(`(${keyword.join("|")})`, "gi");
      paragraph.innerHTML = paragraph.textContent.replace(
        regex,
        `<span class="highlight">$1</span>`
      );
    });
  }, []);

  // Handle the case where the project hasn't been loaded yet
  if (!project) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <main>
            {project.description && !project.introduction && (
              <section id="sectionOne" style={{ margin: "7rem 0 10rem 0" }}>
                <motion.div
                  initial={{ opacity: 0, y: "10%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "-10%" }}
                  transition={{ duration: 0.5, ease: "linear" }}
                >
                  <h1 className="title">{project.name}</h1>
                  <div className="row g-1">
                    <div className="col-auto">
                      <p
                        style={{
                          backgroundColor: "rgb(var(--dark-color), .1)",
                          width: "max-content",
                          padding: ".5rem",
                          borderRadius: "var(--border-radius)",
                        }}
                      >
                        <span
                          className="fw-bold"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <p style={{ margin: "0" }}>
                            {project.screens} Screens
                          </p>
                        </span>
                      </p>
                    </div>
                    <div className="col">
                      <p
                        style={{
                          backgroundColor: "rgb(var(--dark-color), .1)",
                          width: "max-content",
                          padding: ".5rem",
                          borderRadius: "var(--border-radius)",
                        }}
                      >
                        <span className="fw-bold">{project.type}</span>
                      </p>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="row g-2"
                  style={{ color: `${project.h1Color}` }}
                  initial={{ opacity: 0, y: "10%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "-10%" }}
                  transition={{ duration: 0.5, delay: 0.3, ease: "backOut" }}
                >
                  <div className="col-md-4">
                    <div
                      className="card h-100"
                      style={{
                        backgroundColor: "rgba(var(--dark-color), .05)",
                        border: "1px solid rgba(var(--dark-color), .2)",
                      }}
                    >
                      <div className="card-body">
                        <div
                          className="row gap-5 py-4"
                          style={{
                            color: "rgb(var(--dark-color))",
                            width: "calc(100% - 1rem)",
                          }}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <p data-cs-section-heading="/">ROLE</p>
                              </div>
                              <div className="col-12">
                                <h6 data-cs-normalized>{project.myRole}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <p data-cs-section-heading="/">TEAM</p>
                              </div>
                              <div className="col-12">
                                <h6 data-cs-normalized>{project.team}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <p data-cs-section-heading="/">TIMELINE</p>
                              </div>
                              <div className="col-12">
                                <h6 data-cs-normalized>{project.duration}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div
                      className="card h-100"
                      style={{
                        backgroundColor: "rgba(var(--dark-color), .05)",
                        border: "1px solid rgba(var(--dark-color), .2)",
                      }}
                    >
                      <div className="card-body">
                        <div
                          className="row gap-5 py-4"
                          style={{
                            color: "rgb(var(--dark-color))",
                            width: "calc(100% - 1rem)",
                          }}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <p data-cs-section-heading="/">OVERVIEW</p>
                              </div>
                              <div className="col-12">
                                <h6 data-cs-normalized>
                                  {project.description}
                                </h6>
                              </div>
                            </div>
                          </div>
                          {project.siteLink != "PENDING" && (
                            <Link
                              to={project.siteLink}
                              target="_blank"
                              style={{ padding: "0 1.8rem" }}
                            >
                              <ReusableBtn name="Site Preview" />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col px-0">
                        <div
                          className="card h-100"
                          style={{
                            backgroundColor: "rgba(var(--dark-color), .05)",
                            border: "1px solid rgba(var(--dark-color), .2)",
                          }}
                        >
                          <div className="card-body">
                            <img
                              src={project.img[0].img}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
                <div className="offset">
                  <div className="container-fluid">
                    {project.problem && (
                      <div className="row g-2 gap-5 mt-5">
                        <div className="col-12 p-0" data-animation="fade-in">
                          <p
                            className="text-md-start"
                            data-cs-section-heading="/"
                          >
                            TASK OVERVIEW{" "}
                          </p>
                          <div>
                            <div className="col-12">
                              <p data-cs-normalized>{project.problem}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-0" data-animation="fade-in">
                          {/* <p data-cs-section-heading="/">The Goal</p> */}

                          <div>
                            <p data-cs-enlarged-callout>{project.solution}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            )}

            {project.description && !project.introduction && (
              <section id="sectionTwo">
                <motion.div
                  className="jumbotron"
                  ref={motionRef}
                  initial={{ opacity: 0, y: 60 }}
                  animate={controls}
                >
                  <div className="container-fluid">
                    {project.designProcess && (
                      <div style={{ marginTop: "4rem" }}>
                        <p data-cs-main-heading="Design Process">
                          {" "}
                          <span>Design</span> Process
                        </p>
                      </div>
                    )}
                    {/* {project.cardDesignProcess && (
                      <div
                        className="bap"
                        style={{ color: "var(--dark-color)" }}
                      ></div>
                    )} */}

                    {project.designProcess && (
                      <div
                        className="row gy-md-4"
                        style={{ margin: "5rem auto" }}
                      >
                        <div className="col-12 dp-sec">
                          <div className="row gap-4">
                            <div className="col-md">
                              <div className="row gap-4">
                                <div className="col-12">
                                  <div
                                    className="circle-dp"
                                    style={{
                                      width: "30px",
                                      aspectRatio: 1,
                                      backgroundColor: "var(--accent-three)",
                                      borderRadius: "50px",
                                      padding: ".5rem",
                                    }}
                                  ></div>
                                </div>
                                <div className="col-12">
                                  <h3>Emphatize & Define</h3>
                                </div>
                                <div className="col-12">
                                  <p data-cs-normalized>
                                    Understand the users, their needs, and
                                    define the core problem
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="row gap-4">
                                <div className="col-12">
                                  <div
                                    className="circle-dp"
                                    style={{
                                      width: "30px",
                                      aspectRatio: 1,
                                      backgroundColor: "var(--accent-three)",
                                      borderRadius: "50px",
                                      padding: ".5rem",
                                    }}
                                  ></div>
                                </div>
                                <div className="col-12">
                                  <h3>Ideate</h3>
                                </div>
                                <div className="col-12">
                                  <p data-cs-normalized>
                                    Turn idea from concept and brainstorm to MVP
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="row gap-4">
                                <div className="col-12">
                                  <div
                                    className="circle-dp"
                                    style={{
                                      width: "30px",
                                      aspectRatio: 1,
                                      backgroundColor: "var(--accent-three)",
                                      borderRadius: "50px",
                                      padding: ".5rem",
                                    }}
                                  ></div>
                                </div>
                                <div className="col-12">
                                  <h3>Design</h3>
                                </div>
                                <div className="col-12">
                                  <p data-cs-normalized>
                                    Sketch out the product to align the user
                                    needs
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="row gap-4">
                                <div className="col-12">
                                  <div
                                    style={{
                                      width: "30px",
                                      aspectRatio: 1,
                                      backgroundColor: "var(--accent-three)",
                                      borderRadius: "50px",
                                      padding: ".5rem",
                                    }}
                                  ></div>
                                </div>
                                <div className="col-12">
                                  <h3>Prototype, Test & Iterate</h3>
                                </div>
                                <div className="col-12">
                                  <p data-cs-normalized>
                                    Create interactive versions to explore ideas
                                    and flows. Validate the prototype with users
                                    and refine based on feedback
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12" style={{ marginTop: "6rem" }}>
                          <p data-cs-normalized>
                            Our achievements depicted in numbers:{" "}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <DesignTimelineSvg
                            designProcess={project.designProcess}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "clamp(2rem, 10vw, 5rem)",
                          }}
                        >
                          <DesignTimelineEx />
                        </div>

                        <div className="col-12">
                          <div className="row">
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </motion.div>

                {/* Top Scroll */}
                <a href="#home" className="top-scroll">
                  <i className="fas fa-angle-up"></i>
                </a>
              </section>
            )}

            {project.researchAnalysis && (
              <section id="sectionThree" data-animation="fade-in">
                <div className="fixed-background">
                  <div className="container-fluid">
                    <div>
                      <div style={{ marginTop: "4rem" }}>
                        <p data-cs-main-heading="Resch & Analysis">
                          {" "}
                          <span>Research &</span> Analysis
                        </p>
                      </div>
                      <div style={{ marginTop: "6rem" }}>
                        <div className="text-md-start">
                          <div className="row gap-5">
                            <div
                              className="col-lg-12"
                              data-animation="floatIn_buttom"
                            >
                              <p data-cs-section-heading="/">
                                Research Objectives
                              </p>
                              <div className="feature">
                                <span className="fa-layers fa-3x">
                                  <i className="fas fa-code orange"></i>
                                </span>
                              </div>
                              <p data-cs-normalized data-animation="fade-in">
                                {project.researchAnalysis.research}
                              </p>

                              <p data-cs-normalized data-animation="fade-in">
                                {project.researchAnalysis.research1}
                              </p>
                              <div data-cs-normalized data-animation="fade-in">
                                <p>{project.researchAnalysis.research2}</p>
                              </div>
                            </div>
                            <div
                              className="col-lg-12"
                              style={{ position: "relative" }}
                              data-animation="fade-in"
                            >
                              <p
                                data-cs-section-heading="/"
                                data-animation="floatIn_buttom"
                              >
                                Research Questions
                              </p>
                              <ResesarchQuestions
                                researchAnalysis={project.researchAnalysis}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {project.userPersona && (
              <section>
                {" "}
                <div
                  className="mb-3 container-fluid"
                  data-animation="fade-in"
                  style={{ marginTop: "6rem" }}
                >
                  {" "}
                  <p data-cs-section-heading="/" className="ps-3">
                    User Persona
                  </p>{" "}
                  <div className="feature">
                    {" "}
                    <span className="fa-layers fa-3x">
                      {" "}
                      <i className="fas fa-code"></i>{" "}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="row gap-2">
                  {" "}
                  {project.userPersona.map((persona) => (
                    <div
                      className="col-md"
                      data-animation="fade-in"
                      style={{
                        padding: "0 2rem",
                      }}
                    >
                      {" "}
                      <img
                        src={persona.img}
                        alt="user persona"
                        srcset=""
                        style={{
                          width: "100%",
                        }}
                      />{" "}
                    </div>
                  ))}
                </div>{" "}
              </section>
            )}

            {project.survey && (
              <section className="survey-findings">
                {" "}
                <div className="" style={{ marginTop: "6rem" }}>
                  {" "}
                  <div className="mb-3" data-animation="fade-in">
                    {" "}
                    <p data-cs-section-heading="/" className="ps-4">
                      Preliminary Survey
                    </p>{" "}
                    <p data-cs-normalized className="ps-4">
                      {" "}
                      {project.survey.intro}
                    </p>{" "}
                    <figure data-animation="fade-in">
                      {" "}
                      <div
                        style={{
                          width: "100%",
                          maxHeight: "25rem", // Fixed height for the container
                          overflow: "hidden", // Clips the image
                        }}
                      >
                        {" "}
                        <img
                          src={project.survey.img}
                          alt=""
                          style={{
                            width: "100%",
                            maxHeight: "400px",
                            objectFit: "cover", // Ensures the image covers the container
                            objectPosition: "50% 60%", // Adjust this to control the visible area
                          }}
                        />{" "}
                      </div>{" "}
                      <figcaption style={{ marginTop: "6rem" }}>
                        {" "}
                        <small data-cs-normalized className="ps-md-4">
                          {" "}
                          {project.survey.surveyQuestion}
                        </small>{" "}
                      </figcaption>{" "}
                    </figure>{" "}
                  </div>{" "}
                  <figure>
                    {" "}
                    <img
                      src={project.survey.surveyResult}
                      alt="survey result"
                      srcset=""
                      style={{
                        width: "100%",
                        padding: "0 2rem",
                      }}
                    />{" "}
                    <figcaption
                      style={{
                        margin: "2rem 0",
                        padding: " 0 2rem",
                      }}
                    >
                      {" "}
                      <small data-cs-normalized>
                        {" "}
                        <strong>Insight:</strong> {" " + project.survey.insight}
                      </small>{" "}
                    </figcaption>{" "}
                  </figure>{" "}
                </div>{" "}
                {project.empathy && (
                  <div>
                    <p
                      data-cs-section-heading="/"
                      className="ps-4"
                      style={{ marginTop: "6rem" }}
                    >
                      Empathy Map
                    </p>{" "}
                    <div style={{ padding: "clamp(.5rem, 9vw, 8rem)" }}>
                      <EmpathyMapSvg currentProject={project.id} />
                    </div>
                  </div>
                )}
              </section>
            )}

            {project.competitiveAudit && (
              <section className="competitive_audit" data-animation="fade-in">
                {" "}
                <div className="my-5">
                  {" "}
                  <div className="mb-3 px-md-3">
                    {" "}
                    <p data-cs-section-heading="/" className="text-start">
                      Competitive Audit
                    </p>{" "}
                    <div className="feature">
                      {" "}
                      <span className="fa-layers fa-3x ">
                        {" "}
                        <i className="fas fa-code orange"></i>{" "}
                      </span>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div
                    className="col table-responsive table-cover"
                    data-animation="floatIn_buttom"
                  >
                    {" "}
                    {!project.competitiveAuditHeaders && (
                      <table className="table table-hover  table-bordered">
                        {" "}
                        <thead>
                          {" "}
                          <tr>
                            {" "}
                            <th scope="col">Name</th> <th scope="col">Type</th>{" "}
                            <th scope="col">First Impressions</th>{" "}
                            <th scope="col">Key Features</th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          {" "}
                          {project.competitiveAudit.map((audit) => {
                            return (
                              <tr key={audit.id}>
                                {" "}
                                <th scope="row"> {audit.name}</th>{" "}
                                <td className="type">
                                  {" "}
                                  <span data-type={audit.dataType}>
                                    {" "}
                                    {audit.type}
                                  </span>{" "}
                                </td>{" "}
                                <td> {audit.fstImpression}</td>{" "}
                                <td> {audit.keyFeat}</td>{" "}
                              </tr>
                            );
                          })}
                        </tbody>{" "}
                      </table>
                    )}
                    {project.competitiveAuditHeaders && (
                      <table className="table">
                        {" "}
                        <thead>
                          {" "}
                          <tr>
                            {" "}
                            {project.competitiveAuditHeaders.map(
                              (header, index) => {
                                return (
                                  <th scope="col" key={index}>
                                    {" "}
                                    {header.title}
                                  </th>
                                );
                              }
                            )}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          {" "}
                          {project.competitiveAudit.map((data, index) => {
                            return (
                              <tr>
                                {" "}
                                <th scope="row"> {data.name}</th>{" "}
                                {data.sign.map((sign) => {
                                  return (
                                    <td key={index}>
                                      {" "}
                                      {sign.mark === "true" ? (
                                        <DoneRoundedIcon />
                                      ) : (
                                        <ClearRoundedIcon />
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>{" "}
                      </table>
                    )}
                  </div>{" "}
                </div>{" "}
              </section>
            )}

            {project.userJourneyMap && (
              <section className="">
                {" "}
                <div style={{ margin: "10rem auto" }}>
                  {" "}
                  <div className="mb-3 px-md-3" data-animation="fade-in">
                    {" "}
                    <p
                      data-cs-main-heading="Journey Map"
                      className="text-start"
                    >
                      Journey Map
                    </p>{" "}
                    <div style={{ marginTop: "6rem" }}>
                      <p data-cs-enlarged-callout>
                        {" "}
                        {project.userJourneyMap.description}
                      </p>{" "}
                    </div>
                  </div>
                  {project.userJourneyMap.journeyMap && (
                    <div className="col" data-animation="floatIn_buttom">
                      <JourneyMapSvg />
                    </div>
                  )}
                </div>{" "}
              </section>
            )}

            {project.userInterviews && (
              <section className="competitive_audit">
                {" "}
                <div>
                  {" "}
                  <div className="mb-3 px-md-3" data-animation="floatIn_buttom">
                    {" "}
                    <p data-cs-section-heading="/">User Interviews</p>
                    <div className="row g-3 my-3">
                      {" "}
                      <div className="col-md">
                        {" "}
                        <p
                          style={{
                            margin: "1rem 0",
                          }}
                          data-cs-normalized
                        >
                          {" "}
                          {project.userInterviews[0].intro}
                        </p>{" "}
                        <h1
                          style={{
                            lineHeight: "1.5",
                            margin: "1rem 0 2rem 0",
                          }}
                        >
                          {" "}
                          <q data-cs-quoted>
                            {" "}
                            {project.userInterviews[0].quote}
                          </q>{" "}
                        </h1>{" "}
                        <p
                          style={{
                            opacity: ".5",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {" "}
                          — {project.userInterviews[0].participant}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-md">
                        {" "}
                        <img
                          src={project.userInterviews[0].img}
                          style={{
                            width: "100%",
                            borderRadius: "var(--border-radius)",
                          }}
                          alt="interview"
                          srcset=""
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row g-3 my-5">
                      {" "}
                      <div className="col-md">
                        {" "}
                        <img
                          src={project.userInterviews[1].img}
                          style={{
                            width: "100%",
                            borderRadius: "var(--border-radius)",
                          }}
                          alt="interview"
                          srcset=""
                        />{" "}
                      </div>{" "}
                      <div className="col-md">
                        {" "}
                        <p
                          style={{
                            margin: "1rem 0",
                          }}
                          data-cs-normalized
                        >
                          {" "}
                          {project.userInterviews[1].intro}
                        </p>{" "}
                        <h1
                          style={{
                            lineHeight: "1.5",
                            margin: "1rem 0 2rem 0",
                          }}
                        >
                          {" "}
                          <q data-cs-quoted>
                            {" "}
                            {project.userInterviews[1].quote}
                          </q>{" "}
                        </h1>{" "}
                        <p
                          style={{
                            opacity: ".5",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {" "}
                          — {project.userInterviews[1].participant}
                        </p>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </section>
            )}

            {project.expertInterviews && (
              <section className="competitive_audit">
                {" "}
                <div className="mt-4">
                  {" "}
                  <div className="mb-3 px-md-3" data-animation="floatIn_buttom">
                    {" "}
                    <p data-cs-section-heading="/">Expert Interviews</p>{" "}
                    <div className="row g-3 my-3">
                      {" "}
                      {project.expertInterviews.map((response) => {
                        return (
                          <div className="col-md">
                            {" "}
                            <p
                              style={{
                                margin: "1rem 0",
                              }}
                              data-cs-normalized
                            >
                              {" "}
                              {response.intro}
                            </p>{" "}
                            <h1
                              style={{
                                lineHeight: "1.5",
                                margin: "1rem 0 2rem 0",
                              }}
                            >
                              {" "}
                              <q data-cs-quoted> {response.quote}</q>{" "}
                            </h1>{" "}
                            <p
                              style={{
                                opacity: ".5",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              — {response.participant}
                            </p>{" "}
                          </div>
                        );
                      })}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </section>
            )}

            {project.researchSummary && (
              <section id="research_summary">
                {" "}
                <div
                  className="container"
                  style={{
                    margin: "10rem auto 15rem auto",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      margin: "5rem auto",
                    }}
                  >
                    {" "}
                    <div className="heading-left">
                      {" "}
                      <h3 className="heading" data-animation="fade-in">
                        {" "}
                        Research Summary{" "}
                      </h3>{" "}
                      <div className="heading-underline-left "></div>{" "}
                    </div>{" "}
                    <div>
                      {" "}
                      <img src="" alt="" />{" "}
                    </div>{" "}
                    <p data-cs-normalized data-animation="fade-in">
                      {" "}
                      {project.researchSummary.description}
                    </p>{" "}
                  </div>{" "}
                  <NumberedCard understandUsers={project.understandnUsers} />
                </div>
              </section>
            )}

            {project.designGoal && (
              <section className="design_goal">
                <div className="container-fluid">
                  <p
                    data-cs-main-heading="Design Goal"
                    data-animation="fade-in"
                  >
                    Design Goal
                  </p>
                  <div
                    style={{
                      margin: "7rem 0",
                    }}
                    data-animation="fade-in"
                  >
                    <div
                      className="circle_wrapper"
                      style={{
                        borderRadius: "var(--border-radius)",
                      }}
                    >
                      {project.goals.map((goal, index) => {
                        return (
                          <div className="circle" key={index}>
                            {goal.contents.map((contentItem, index) => {
                              return (
                                <div
                                  className="dangerous-text"
                                  key={index}
                                  dangerouslySetInnerHTML={{
                                    __html: contentItem.content,
                                  }}
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>
            )}

            {project.ideation && (
              <section className="ideation " style={{ margin: "15rem 0" }}>
                <div className="container-fluid">
                  <div>
                    <p data-cs-main-heading="IDEATION" data-animation="fade-in">
                      IDEATION
                    </p>
                  </div>{" "}
                </div>{" "}
                <figure>
                  {" "}
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "25rem",
                      overflow: "hidden",
                    }}
                  >
                    {" "}
                    <img
                      src="/images/project/educratic-images/ideation-images/ideation.png"
                      alt="ideation"
                      style={{
                        width: "100%",
                        maxHeight: "400px",
                        objectFit: "cover",
                        objectPosition: "50% 60%",
                      }}
                    />{" "}
                  </div>{" "}
                  <figcaption
                    style={{
                      margin: "2rem 0",
                    }}
                  >
                    {" "}
                    <small> {project.ideation.caption}</small>{" "}
                  </figcaption>{" "}
                </figure>{" "}
                {project.ideation.map((ideation, index) => {
                  return (
                    <div className="user_flow" key={ideation.id}>
                      {" "}
                      <div className=" container" data-animation="fade-in">
                        {" "}
                        <p data-cs-section-heading="/">
                          {" "}
                          {ideation.title}
                        </p>{" "}
                        <p data-cs-normalized> {ideation.content}</p>{" "}
                      </div>{" "}
                      <ImageCarousel images={ideation.userFlow} />{" "}
                    </div>
                  );
                })}
              </section>
            )}

            {project.designSolution && (
              <section className="design_solution ">
                {" "}
                <div className="container">
                  {" "}
                  <div>
                    {" "}
                    <div className="heading-left">
                      {" "}
                      <h3 className="heading" data-animation="fade-in">
                        {" "}
                        {project.designSolution.title}
                      </h3>{" "}
                      <div className="heading-underline-left "></div>{" "}
                    </div>{" "}
                    <h3 className="text-start" data-animation="fade-in">
                      {" "}
                      {project.designSolution.subHeading}
                    </h3>{" "}
                    <p data-animation="fade-in" data-cs-normalized>
                      {" "}
                      {project.designSolution.content}
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="visual_design_container container">
                  {" "}
                  <h3 className="text-start" data-animation="fade-in">
                    {" "}
                    {project.designSystemHeading}
                  </h3>{" "}
                  <p data-animation="fade-in" data-cs-normalized>
                    {" "}
                    {project.designSolution.designSystemContent}
                  </p>{" "}
                  {project.designSolution.designSystemImg && (
                    <img
                      data-animation="floatIn_buttom"
                      src={project.designSolution.designSystemImg}
                      alt="design system"
                      style={{
                        width: "100%",
                        borderRadius: "var(--border-radius)",
                      }}
                    />
                  )}
                  <hr
                    className="featurette-divider"
                    data-animation="grow_center"
                  />{" "}
                </div>{" "}
                <div className="key_features container">
                  {" "}
                  <div className="mb-3" data-animation="floatIn_buttom">
                    {" "}
                    <h3 className="text-start">
                      {" "}
                      {project.designSolution.subTitle}
                    </h3>{" "}
                  </div>{" "}
                </div>{" "}
                <img
                  src={project.designSolution.designImages}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              </section>
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default CaseStudy;
